<template>
  <div class="address-contaner">
    <!-- 我的设备列表 start -->
    <el-row class="device-list">
      <el-row class="header" type="flex" align="middle" justify="space-between">
        <p class="title">我的地址</p>
        <span class="add" @click="addAddress">
          <router-link :to="{ name: 'addpos' }">+新增地址</router-link>
        </span>
      </el-row>

      <!-- 表格展示 start -->
      <el-row class="table-box">
        <el-table
          :data="tableData"
          style="width: 100%"
          :header-cell-style="{ background: '#F8F9FB', height: '54px' }"
          header-cell-class-name="table-header"
        >
          <el-table-column prop="name" label="联系人">
            <template slot-scope="{ row }">{{ row.name || "-" }}</template>
          </el-table-column>
          <el-table-column prop="phone" label="联系方式"
            ><template slot-scope="{ row }">{{ row.phone || "-" }}</template>
          </el-table-column>
          <el-table-column prop="area" label="所在地区"
            ><template slot-scope="{ row }">{{
              row.area.trim() ? row.area : "-"
            }}</template>
          </el-table-column>
          <el-table-column prop="address" label="详细地址">
            <template slot-scope="{ row }">{{ row.address }}</template>
          </el-table-column>
          <el-table-column
            align="center"
            prop="handle"
            label="操作"
            width="228"
          >
            <template slot-scope="scope">
              <!-- 删除 -->
              <span
                @click="handleDel(scope.$index, scope.row)"
                class="f14 see p b"
              >
                <span class="del">删除</span>
              </span>
              <!-- 编辑 -->
              <span class="f14 see p b">
                <span
                  @click="handleEdit(scope.$index, scope.row)"
                  class="f14 see p b"
                >
                  <span class="del" style="color: #007bd9">编辑</span>
                </span>
                <!-- <router-link :to="{ name: 'editpos' }">
                  <span class="edit">编辑</span></router-link
                > -->
              </span>
            </template>
          </el-table-column>
        </el-table>
      </el-row>

      <!-- 表格展示 end -->
      <!-- 分页 -->
      <el-pagination
        background
        layout="prev, pager, next"
        :total="listTotal"
        :page-size="pageSize"
        :current-page="page"
        @current-change="pageChange"
        @prev-click="prevChange"
        @next-click="nextChange"
      >
      </el-pagination>
    </el-row>
  </div>
</template>

<script>
export default {
  data() {
    return {
      tableData: [], //表格数据

      // 分页相关数据
      page: 1,
      pageSize: 5,
      listTotal: 0,
    };
  },

  mounted() {},

  created() {
    // 通过用户id查询
    this.getPosPaging();
  },

  methods: {
    // 通过用户id查询
    getPosPaging() {
      let paramData = {
        pageNo: this.page,
        pageSize: this.pageSize,
      };
      this.$API
        .getPosPaging(paramData)
        .then((res) => {
          if (res.code == 1) {
            this.listTotal = Number(res.data.total);
            this.tableData = [];
            res.data.records.forEach((item, index) => {
              this.tableData.push({
                name: item.contactsName, //系统编号
                phone: item.contactsPhone,
                area: `${item.provinceName} ${item.cityName} ${item.areaName}`,
                address: item.address,
                id: item.id,
              });
            });
          } else {
            this.$message.info(res.msg);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 当前页码改变
    pageChange(page) {
      this.page = page;
      this.getPosPaging();
    },
    prevChange() {},
    nextChange() {},
    // 编辑
    handleEdit(index, row) {
      this.$router.push({ name: "editpos", query: { id: row.id } });
    },
    // 删除
    handleDel(index, row) {
      let paramData = {
        id: row.id,
      };
      this.$alert("确定要删除吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        showCancelButton: true,
        callback: (action) => {
          // 确定  confirm  取消 cancel

          if (action == "confirm") {
            this.$API
              .delOnePos(paramData)
              .then((res) => {
                if (res.code == 1) {
                  this.$message({
                    message: "删除成功",
                    type: "success",
                  });
                  // 通过用户id查询
                  this.getPosPaging();
                } else {
                  this.$message.info(res.msg);
                }
              })
              .catch((err) => {
                console.log(err);
              });
          }
        },
      });
    },
    // 导出处理
    exportHandle() {},
    // 新增地址
    addAddress() {},
  },
};
</script>

<style lang="scss">
.address-contaner {
  width: 100%;
  height: 100%;
  // 分頁
  .el-pagination {
    text-align: center;
    margin-bottom: 46px;
  }
  .el-pagination.is-background .el-pager li:not(.disabled):hover {
    color: #333333;
  }
  .el-pagination.is-background .el-pager li:not(.disabled).active {
    background-color: red;
    color: #ffffff;
  }

  .del {
    color: #ff0015;
    margin: 0 5px;
  }
  .edit {
    color: #007bd9;
    margin: 0 5px;
  }
  box-sizing: border-box;
  padding: 32px;
  // 设备列表
  .device-list {
    width: 100%;
    .header {
      height: 55px;
      box-sizing: border-box;
      padding-bottom: 25px;
      border-bottom: 1px solid #eeeeee;
    }

    .add {
      color: #007bd9;
      font-size: 14px;
      cursor: pointer;
    }
    .title {
      // padding-bottom: 16px;
      font-size: 22px;
      font-weight: bold;
      color: #333333;
      border: none;
    }
  }
  // 表格
  .table-box {
    margin-bottom: 48px;
    margin-top: 30px;
    .handle {
      ul {
        height: 100%;
        width: 100%;
        padding: 0 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
      }
      li {
        // float: left;
        display: inline-block;
        font-size: 14px;
        font-weight: 400;
        color: #007bd9;
        line-height: 22px;
        margin-right: 16px;
        &.del {
          color: #ff0015;
        }
        &.edit {
          color: #007bd9;
        }
      }
    }
  }
  // 分頁
  .el-pagination {
    text-align: center;
    margin-bottom: 46px;
  }
  .el-pagination.is-background .el-pager li:not(.disabled):hover {
    color: #333333;
  }
  .el-pagination.is-background .el-pager li:not(.disabled).active {
    background-color: red;
    color: #ffffff;
  }
}
</style>